import styled from "styled-components"
import { space } from "styled-system"
import { Link } from "gatsby"
import Text from "./text"

export const Container = styled.nav`
  ${space}
  grid-column: main-start / main-end;
`

Container.defaultProps = {
  my: ["y.mobile.3.0", "y.desktop.3.0"],
}

export const PageLink = styled(Text.Label)`
  display: inline-block;
  > * {
    vertical-align: middle;
    line-height: normal;
  }
  &.active,
  &:hover {
    opacity: 0.5;
  }
`

PageLink.defaultProps = {
  as: Link,
  mr: "x.3",
}
