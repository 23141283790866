import React from "react"
import { Container, PageLink } from "./pagination.styles"
import ArrowIcon from "./icons/arrow-icon"

export default ({ path, pageInfo }) => {
  const { currentPage, pageCount } = pageInfo
  if (pageCount === 1) return null

  const hasPrevPage = currentPage > 1
  const hasNextPage = currentPage < pageCount
  const prevPage = currentPage === 2 ? path : `${path}/${currentPage - 1}`
  const nextPage = `${path}/${currentPage + 1}`

  return (
    <Container>
      {hasPrevPage && (
        <PageLink to={prevPage} rel="prev">
          <ArrowIcon rotate={180} size={24} /> <span>Previous Page</span>
        </PageLink>
      )}
      {Array.from({ length: pageCount }, (_, index) => (
        <PageLink
          key={`pagination-number${index + 1}`}
          activeClassName="active"
          to={index === 0 ? path : `${path}/${index + 1}`}
        >
          {index + 1}
        </PageLink>
      ))}
      {hasNextPage && (
        <PageLink to={nextPage} rel="next">
          <span>Next Page</span> <ArrowIcon size={24} />
        </PageLink>
      )}
    </Container>
  )
}
