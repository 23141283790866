import React from "react"
import { Link, graphql } from "gatsby"
import get from "lodash.get"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/page-hero"
import Grid from "../components/grid"
import Breadcrumbs from "../components/breadcrumbs"
import Listing from "../components/listing"
import Pagination from "../components/pagination"

export const pageQuery = graphql`
  query BlogByCategory($uid: String!, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    prismicCategory(uid: { eq: $uid }) {
      uid
      data {
        name
        description {
          text
          richText
        }
        image {
          alt
          copyright
          gatsbyImageData(layout: CONSTRAINED, width: 504, height: 280)
        }
      }
    }
    allPrismicPost(
      sort: { fields: data___date, order: DESC }
      filter: { data: { category: { uid: { eq: $uid } } } }
      limit: $limit
      skip: $skip
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageCount
        totalCount
        currentPage
      }
      nodes {
        id
        uid
        type
        data {
          image {
            alt
            copyright
            gatsbyImageData(layout: CONSTRAINED, width: 328, height: 210)
          }
          category {
            document {
              ... on PrismicCategory {
                id
                uid
                type
                data {
                  name
                }
              }
            }
          }
          title
          date
          body {
            ... on PrismicPostDataBodyText {
              id
              slice_type
              slice_label
              primary {
                text {
                  text
                  richText
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function Category({ location, data }) {
  const category = get(data, `prismicCategory`)
  const posts = get(data, `allPrismicPost.nodes`)
  const pageInfo = get(data, `allPrismicPost.pageInfo`)

  if (!category) return null

  return (
    <Layout location={location}>
      <SEO
        title={`${category.data.name} | Page ${pageInfo.currentPage}`}
        description={category.data.description.text}
        image={category.data.image}
      />

      <Grid>
        <Hero
          title={category.data.name}
          subtitle={category.data.description}
          image={category.data.image}
        />
        <Breadcrumbs>
          <Link to="/">Home</Link>
          <Link to="/news">News</Link>
          <span>{category.data.name}</span>
        </Breadcrumbs>
        <Listing nodes={posts} />
        <Pagination
          path={`/news/category/${category.uid}`}
          pageInfo={pageInfo}
        />
      </Grid>
    </Layout>
  )
}
